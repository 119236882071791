html {
    scroll-behavior: smooth;
}
body{
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
p, li{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color:#354537;
}
h1,h2,h3,h4,h5,h6{
    font-family: 'Oswald', sans-serif;
}

.logo{
    height: auto;
}

#navbarScrollGreen{
    background-color: #354537;;
  }

#nav_bar{
    background-color: #FFF;
}

#nav_bar a, 
#navbarScrollGreen a{
    text-transform: uppercase;
    color:#354537;
    font-family: 'Oswald', sans-serif;
    margin: auto 15px;
}
#nav_bar a:hover, #nav_bar .active, .nav-link.active{
    background-color: #354537;
    color:#FFF;
}
#navbarScrollGreen a{
    color:#FFF;
}
#navbarScrollGreen a:hover,
#navbarScrollGreen a.nav-link.active, #navbarScrollGreen .show>a.nav-link{
    background-color:#FFF !important;
    color:#354537;
}
/* #nav_bar a.nav-link.active{
    background-color: #354537;
    color:#FFF;
} */
.fixed-top{
    background-color: #FFF;
}
.slider{
    overflow: hidden;
}

.slider .active_slider{
    background-size: cover;
    background-position: center;
}
.slider .active_slider::after{
    display: block;
    clear: both;
    content: "";
    background: rgba(0,0,0,.4);
    position: absolute;
    z-index: 1;
    min-height: 100vh;
    left: 0;
    top: 0;
    right: 0;
}
.slider .active_slider .caption{
    z-index: 999;
    font-family: 'Oswald', sans-serif;
    transform: translate(-50%, -50%);
    top: 50%;
    left:50%;
    bottom: inherit !important;
    right: inherit !important;
}
.slider .active_slider .caption h3{
    font-size: 4vw;
}
.slider .active_slider .caption  p{
    font-size: 1.5vw;
    color:#FFF;
}
.inner_green{
    height: 100%;
    width: 100%;
}

#about_section, 
#services_section, 
#slogan_section, 
#contact_section, 
#join_us{
    min-height: 850px;
}
#about_section h2{
    color:#344638;
    font-family: 'Oswald', sans-serif;
    font-size: 2.9rem;
}
#services_section{
    background-color: #f8f9fa;
}
#works_section{
    background-color: #FFF;
}
#works_section h4{
    font-size: 2.9rem;
    color:#344638;
}
#services_section h2{
    color:#354537;
    font-family: 'Oswald', sans-serif;
}
#services_section p, #services_section li {
    list-style-type: none;
}

#about_section{
    /* background-repeat: repeat-x;
    background-position: center bottom;
    background-size: 200px; */
}

#works_section p{
    font-size: .9rem !important;
}

#gallery_section{
    background-color: #f8f9fa;
}

#join_us{
    
}
#contact_section{
    background-color: #f8f9fa;
}
#contact_section ul{
    list-style-type: none;
    margin:0;
    padding: 0;
}

.innerContainer{
    background-color: #FFF;
}

.service{
    height:300px;
    max-height: 300px;
    overflow: hidden;
    background-color:#FFF;
}
.service:hover{
    background-color:#66000000;
}
.service img{
    
}
.service h5{
    margin: 0;
}

.quote{
    font-family: 'Oswald', sans-serif !important;
    text-transform: uppercase;
    background-color: #344638 !important;
    color:#FFF !important;
}

.quote:hover{
    background-color: #66000000 !important;
    color:#344638 !important;
}

#gallery_section h2,
#works_section h2,
#join_us h2,
#contact_section h2{
    color:#354537;
    font-family: 'Oswald', sans-serif;
}
#contact_section a{
    text-decoration: none;
    color:#354537;
}
footer{
    background-color: #354537;
}
#footer p, #footer a{
    color:#FFF;
    font-size: 1rem;
    text-decoration: none;
}
.img_gallery{
    background-position: center;
    background-size: cover;
    border: solid 5px #FFF !important;
    min-height: 200px;
    cursor:pointer;
}

.lightbox{
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    background-color:rgba(0,0,0,.6);
    padding:50px;
    z-index:99999;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.lightbox img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* max-width:800px; */
}

.headerLogo {
    position: sticky;
    top: 0px;
    background-color: #BFDBFE;
    height: 6rem;
    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
  }
  #navbarScroll{
    background-color: #FFF;
  }
  
  
/* .logo .hide {
    display: none;
} */

@media (min-width:320px){
    .logo{
        width: 55px;
    }
    li, p{
        font-size: 1rem;
    }
    #nav_bar, #navbarScrollGreen{
        border-radius: 0;
    }
    #about_section img{
        width:50%;
        margin-top: 10px;
    }
    #about_section, 
    #services_section, 
    #slogan_section, 
    #contact_section, 
    #join_us{
        min-height: 100%;
    }
    #about_section h2,
    #gallery_section h2, 
    #works_section h2, 
    #services_section h2,
    #join_us h2, 
    #contact_section h2{
        font-size: 2.6rem;
    }
    .quote{
        font-size:1.4rem !important;
    }
    .lightbox{
        padding: 25px;
    }
    .lightbox img{
        max-height: 80%;
    }
}

@media (min-width: 786px) { 
    .logo{
        width: 100px;
    }
    .quote{
        font-size:1.5rem !important;
    }
    li, p{
        font-size: 1.2rem;
    }
    .slider{
        min-height: 100vh;
    }
    .slider .active_slider {
        min-height: 100vh;
    }
    .innerContainer{
        height: 250px;
    }
    #navbarScroll{
        background-color: transparent;
    }
    /* #nav_bar, #navbarScrollGreen{
        border-radius: 50rem;
    } */
    #about_section img{
        width:auto;
        margin-top: auto;
    }
    #about_section, 
    #services_section, 
    #slogan_section, 
    #contact_section, 
    #join_us{
        min-height: 100vh;
    }
    #about_section h2,
    #services_section h2,
    #gallery_section h2, 
    #works_section h2, 
    #join_us h2, 
    #contact_section h2{
        font-size: 2.9rem;
    }
    .lightbox img{
        max-height: 80%;
    }
 }

 @media (min-width: 992px) {
    #nav_bar, #navbarScrollGreen{
        border-radius: 50rem;
    }
 }

/* font-family: 'Montserrat', sans-serif;
font-family: 'Oswald', sans-serif; */